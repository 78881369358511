import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Button, Card } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCartArrowDown)
const TextTrained = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="row ">
          <div className="col-lg-7">
            <div className="vl mb-3"><h3 className="vlh3">What Is An In-Home Trained Attendant Service?</h3></div>
            <p>An at-home trained attendant service offers comprehensive support. The everyday needs of a person in 
              need are taken care of by trained attendants, sometimes referred to as carers, nurses for home, and
              attendants. They assist with personal hygiene, movement, food, light housework, and grooming.
              They are also trained to check overall health by 
              routinely taking vital signs including blood pressure and heart rate.</p>
              <br />
              <div className="vl mb-3"><h3 className="vlh3">When Do You Need Us?</h3></div> 
            <div className="row">
              <div className="col-lg-5 box">
                <h4 className="color">Elder Care</h4>
                <p> If you have an elderly loved one who needs support and assistance with daily activities,
                we give them the care and compassion
                they deserve. Get our 12 / 24-hr care attendant service at home.</p>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-5 box">
                <h4 className="color">Mother and Baby Care</h4>
                <p>Both mother and newborn need constant attention while feeding, bathing, housekeeping, and
                more. The support of a well-trained attendant at home will do them wonders.</p>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-lg-5 box">
                <h4 className="color">Health and Lifestyle Management</h4>
                <p>If a loved one needs help moving around or to be monitored regularly, a trained attendant
                   is exactly who you need at home</p>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-5 box">
                <h4 className="color">Post-Operative Support</h4>
                <p>It might be challenging for the family to care for someone who has recently undergone surgery.
                 However, by monitoring the patient's vital signs and 
                helping the family, a professional in-home attendant can simplify matters.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <CardGroup>
              <Card className="div banner-content">
                  <Link to="product/154">
                    <Card.Img variant="top" src="/assets/img/banner/trained.jpg"/>
                    <Card.Body>
                      <Card.Title className="card_title">Attendant For 4 Hours</Card.Title>
                      <Card.Text>Hire Attendant For 4 Hours</Card.Text>
                      <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                    </Card.Body>
                  </Link>
              </Card>  
              <Card className="div">
                <Link to="product/155">
                  <Card.Img variant="top" src="/assets/img/banner/trained.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Attendant For 8 Hours</Card.Title>
                    <Card.Text>Hire Attendant For 8 Hours</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>
            </CardGroup>
            <CardGroup>
              <Card className="div banner-content">
                  <Link to="product/156">
                    <Card.Img variant="top" src="/assets/img/banner/trained.jpg"/>
                    <Card.Body>
                      <Card.Title className="card_title">Attendant For 12 Hours</Card.Title>
                      <Card.Text>Hire Attendant For 12 Hours</Card.Text>
                      <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                    </Card.Body>
                  </Link>
              </Card>  
              <Card className="div">
                <Link to="product/157">
                  <Card.Img variant="top" src="/assets/img/banner/trained.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Attendant For 24 Hours</Card.Title>
                    <Card.Text>Hire Attendant For 24 Hours</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>
            </CardGroup>
            <CardGroup>
              <Card className="div banner-content">
                  <Link to="product/158">
                    <Card.Img variant="top" src="/assets/img/banner/trained.jpg"/>
                    <Card.Body>
                      <Card.Title className="card_title">15 Days</Card.Title>
                      <Card.Text>Package For 15 Days</Card.Text>
                      <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                    </Card.Body>
                  </Link>
              </Card>  
              <Card className="div">
                <Link to="product/159">
                  <Card.Img variant="top" src="/assets/img/banner/trained.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">20 Days</Card.Title>
                    <Card.Text>Package For 20 Days</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>
              <Card className="div">
                <Link to="product/160">
                  <Card.Img variant="top" src="/assets/img/banner/trained.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">30 Days</Card.Title>
                    <Card.Text>Package For 30 Days</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>
            </CardGroup>

          </div>
          



              <div className="vl mb-3 mt-5"><span></span><h3>How Can We Help?</h3></div>
              <p>Our home health care professionals offer assistance to people in need while they are comfortable
               in their own homes. In addition to other things, they can assist with oral medication, mobility,
                nutrition, personal hygiene, and other things. You can employ the services of a 
              caretaker for either 12 hours or 24 hours, depending on your needs.</p>
              <div className="vl mb-3"><h3>FAQ’S </h3></div>
              <h4>Q – What are the responsibilities of Glizmed Attendant?</h4>
              <p>A- Glizmed professional staff members can aid you with giving oral medication, managing feeding 
              tubes, assisting with ambulation, checking 
              patients' vital signs, grooming the patient personally, and more.</p>
              <h4>Q – Will the trained attendant wash clothes and utensils for the family?</h4>
              <p>A – No, our attendant will only help the patient to perform their daily living activities.</p>
              <h4>Q – Are Glizmed trained attendants medically qualified?</h4>
              <p>A – No, our trained attendants are not medical professionals. However, we do train our attendants for daily living assistance and basic medical assistance.

</p>
          
            <br />
            <br />
              
        </div>
      </div>
    </Fragment>
  );
};

export default TextTrained;
