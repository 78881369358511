import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Button, Card } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCartArrowDown)
const TextSleep = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <CardGroup className="mb-5">
            <Card className="div banner-content">
                <Link to="product/169">
                  <Card.Img variant="top" src="/assets/img/banner/cpap 420e.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">CPAP – Goodknight 420e</Card.Title>
                    <Card.Text><b>Brand: </b>Goodknight 420E</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/170">
                  <Card.Img variant="top" src="/assets/img/banner/resmed S9.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Resmed S9 Auto 25</Card.Title>
                    <Card.Text><b>Brand:</b>Resmed</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/171">
                  <Card.Img variant="top" src="/assets/img/banner/Resmed_Apnealink.png"/>
                  <Card.Body>
                    <Card.Title className="card_title">Resmed Apnealink Air </Card.Title>
                    <Card.Text><b>Brand:</b>Resmed</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div">
              <Link to="product/172">
                <Card.Img variant="top" src="/assets/img/banner/Resmed_S9_VPAP_ST.jpg"/>
                <Card.Body>
                  <Card.Title className="card_title">Resmed S9 Vpap St</Card.Title>
                  <Card.Text><b>Brand:</b>Resmed</Card.Text>
                  <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                </Card.Body>
              </Link>
            </Card>
          </CardGroup>

          <CardGroup className="mb-5">
            <Card className="div banner-content">
                <Link to="product/173">
                  <Card.Img variant="top" src="/assets/img/banner/Resmed_S9_Autoset.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Resmed S9 Autoset Filter</Card.Title>
                    <Card.Text><b>Brand: </b>Resmed</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/174">
                  <Card.Img variant="top" src="/assets/img/banner/Resmed_S9_vpap_S.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Resmed S9 Vpap S</Card.Title>
                    <Card.Text><b>Brand:</b>Resmed</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/175">
                  <Card.Img variant="top" src="/assets/img/banner/Philips_Remstar_Auto.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Philips Remstar Auto</Card.Title>
                    <Card.Text><b>Brand:</b>Philips</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div">
              <Link to="product/176">
                <Card.Img variant="top" src="/assets/img/banner/Hospital_Nasal_Mask.jpg"/>
                <Card.Body>
                  <Card.Title className="card_title">Hospital Nasal Mask</Card.Title>
                  <Card.Text><b>Brand:</b> Resmed</Card.Text>
                  <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                </Card.Body>
              </Link>
            </Card>
          </CardGroup>
        </div>
      </div>
    </Fragment>
  );
};

export default TextSleep;
