import React, { Fragment ,useState } from "react";
import MetaTags from "react-meta-tags";
import TextSurgeries from "../../data/context/TextSurgeries";
import LayoutOne from "../../layouts/LayoutOne";


const HomeSurgeries = () => {

  return (
    
    <Fragment>
      <MetaTags>
        <title>Glizmed | Surgeries</title>
        <meta
          name="description"
          content="Pharmaceutical Industry."
        />
      </MetaTags>
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-2"
        headerTop="visible"
      >
      <TextSurgeries />
      </LayoutOne>
    </Fragment>
  );
};

export default HomeSurgeries;
