import React, { Fragment } from "react";

const TextVaccination = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="row flex">
          <div className="vl mb-3"><span></span><h3>What Is Vaccination?</h3></div>
          <p>The procedure of delivering a chemical or vaccine that immunises against one or more diseases is known
           as vaccination. It increases human immunity, guards against infection,
           and stops the disease from developing in the vaccinated person.</p>
          <p>Since they each have a distinct meaning, the terms vaccination and immunisation are frequently misused. Immunization is essentially what happens to the body after receiving a vaccination,
           whereas vaccination is the administration of the vaccine.</p>
          <p>Glizmed offers a variety of immunizations in the convenience of your own home. Vaccinations against diseases like H1N1, chicken pox, typhoid, hepatitis B, pneumonia, and cervical cancer are routinely given in India.
           On special request, you can also obtain other new vaccine types.</p>
          

            <div className="vl mb-3"><span></span><h3>When Do You Need Us?</h3></div> 
            <p className="mb-5">The vaccination process is crucial for defending against dangerous infections.
             Simply said, going to a hospital or doctor's office for such a simple process is too stressful,
              especially if you can receive immunizations at home. Portea provides reasonably priced nursing
               and doctor on call services, who will visit you promptly and provide immunizations in your home.
                All you have to do if you ever need a vaccination is go online and schedule an appointment with us
                , and we'll send you a skilled nurse to give you the shot you need.
             The following is a list of the various vaccines we have on hand:</p> 

            <div className="row text_align">
              <div className="col-lg-3 box">
              <h4>Hepatitis B Vaccination</h4>
              <p> The three-shot hepatitis B immunisation series protects against the hepatitis B virus,
               which can cause cirrhosis, liver cancer, and eventual death. Hepatitis B is 50 to 100% more
                contagious than HIV, and once it has been contracted, there is no specific therapy for the illness,
                 and what medication is available does not always function well. Therefore, it is imperative to get
             vaccinated against this contagious disease for both oneself and one's loved ones.</p>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-3 box">
              <h4>H1N1 Vaccination</h4>
              <p>The H1N1 vaccine guards against illnesses brought on by the influenza virus that sparked a pandemic
               in 2009. It is best to be protected from this sickness than to contract it because of how contagious 
               it is. Despite being treatable, the infection has an 11% fatality rate. Under-18-year-olds and those 
               who are allergic to eggs or gelatin cannot receive the H1N1 immunisation injection.</p>
              
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-3 box">
              <h4>Typhoid Vaccination</h4>
              <p>The fatal "Salmonella Typhi" virus, which causes the virulent illness Typhoid, is prevented with a 
              typhoid immunisation injection. High fever, exhaustion, sluggishness, headache, nausea, vomiting,
               loss of appetite, and rash are all symptoms of typhoid. Additionally very contagious and lethal 
               if untreated,
               typhoid disease. Up to 30% of those who contract it die from it.</p>
              </div>
            </div>  
            <div className="row text_align mt-5">
              <div className="col-lg-3 box">
              <h4>Chicken Pox Vaccination</h4>
              <p> The varicella zoster virus infection that results in the first development of chickenpox is 
              a highly contagious illness. The signs of this illness include a fever, headache, and a skin rash
               that develops small, painful blisters. Even while chickenpox is generally treatable, it can 
               nevertheless be fatal in infants, children, pregnant women, and those with compromised immune
                systems. The best method to prevent 
              chickenpox is to get vaccinated, so make sure everyone in your household does.</p>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-3 box">
              <h4>Pneumonia Vaccination</h4>
              <p>The "Streptococcus pneumonia" bacterium, which can cause pneumonia, sepsis, and meningitis, is
               protected from by the pneumococcal vaccine. The PC13 vaccination, which protects against pneumonia,
                should be administered four times to all children under the age of two. Also advised for adults up
                 to 64 years old is this immunisation. Get your loved ones vaccinated against the deadly disease of
                  pneumonia so that they are protected from it. 
              Pneumonia can be fatal for both children and adults.</p>
              
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-3 box">
              <h4>Cervical Cancer Vaccination</h4>
              <p className="mb-5">Women are protected against the human papillomavirus, a sexually transmitted 
              infection, with the cervical cancer vaccine or HPV vaccine. Thousands of women die from cervical
               cancer each year despite the fact that it is treatable. The likelihood of survival is also influenced
                by early discovery and cancer stage. Get your mother, wife, daughter,
               and sisters immunised as soon as possible to protect them from this deadly disease.</p>
              </div>
            </div>  
        </div>
      </div>
    </Fragment>
  );
};

export default TextVaccination;
