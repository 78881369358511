import React, { Fragment } from "react";
import { Table } from "react-bootstrap";

const TextDiabeticCare = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="row ">
        <div className="vl mb-3 mt-5"><h3 className="vlh3">Our Diabetes Care Program Plan</h3></div>
        <p>With the goal of assisting people in managing their diabetes in a natural and comfortable manner,
         Glizmed's Diabetes Care Program was developed. Our knowledgeable counsellors will collaborate with 
         you to monitor, control, and lower your blood sugar levels. Using information about your interests
          and lifestyle, your counsellor will develop a diet plan just for you.</p>
        <p>With the overriding goal of raising blood sugar levels, our team of diabetic nurses and nutritionists
         communicate frequently (by phone) with each patient to assist them with a variety of problems.</p>

        <div className="vl mb-3 mt-3"><h3 className="vlh3">Our Offering Diabetes Care Packs</h3></div>
        <p>The goal of the country's first comprehensive diabetes management programme is to assist diabetics
         in controlling their blood sugar levels and living a healthy lifestyle. Our knowledge partners Belle
         Sante, who have treated thousands of patients in 32 countries, were consulted in the creation of the
         programme. We'll work with you for a full year to help you master diabetes management on your own.</p>
        
         <h4 className="mb-3 mt-3"><b>REMOTE PACKAGE @ 875/-</b></h4>
          <h5 className="mb-3 mt-3">Basic Eldercare plan at home at a cost Rs.100 per day, includes,</h5>
          <div className="container mb-3">
            <ul>
              <li>⮚ OneTouch Select Plus Simple (SPS) Glucometer</li>
              <li>⮚ Personal Health Coach</li>
              <li>⮚ 10 Testing Strips</li>
            </ul>
          </div>

          <h4 className="mb-3 mt-3"><b>DISCOUNT ON STRIPS REFILL</b></h4>
          <h5 className="mb-3 mt-3">Prime Basic Eldercare plan at home at a cost Rs.167 per day, includes,</h5>
          <div className="container mb-3">
            <ul>
              <li>⮚ 25 strips @ 549 , MRP 625 , Discount 13%</li>
              <li>⮚ 50 strips @ 899, MRP 1095, Discount 18%</li>
            </ul>
          </div>

          <h4 className="mb-3 mt-3"><b>DIABETES STATISTICS</b></h4>
          <div className="container mb-3">
            <ul>
              <li>⮚ 1 out of every 10 people in India is either Diabetic or Pre-Diabetic</li>
              <li>⮚ 1 out of 2 adults with Diabetes is undiagnosed</li>
              <li>⮚ 50 million people suffer from type-2 diabetes.</li>
              <li>⮚ An estimated 3.4 million deaths are caused due to high blood sugar</li>
            </ul>
          </div>
        <div className="vl mb-3 mt-5"><h3 className="vlh3">Best Diabetes Care Package Offers From Glizmed</h3></div>
          <div className="col-lg-7">   
            <Table striped bordered hover >
              <thead>
                <tr>
                  <th>Diabetes care Packages</th>
                  <th>MRP</th>
                  <th>Glizmed Best Offer price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>SPS Glucometer + 10 testing strips + 1 year diet counseling</td>
                  <td>1,390</td> 
                  <td>875</td>   
                </tr>
                <tr>
                  <td>SPS Glucometer + 35 testing strips + 1 year diet counseling	</td>
                  <td>3,000</td>
                  <td>1,299</td>
                </tr>
                <tr>
                  <td>SPS Glucometer + 60 testing strips + 1 year diet counseling</td>
                  <td>3,500</td>
                  <td>1,749</td>
                </tr>
              </tbody>
            </Table>
          </div>   
        </div>
      </div>  
    </Fragment>
  );
};

export default TextDiabeticCare;
