import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';



const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  1024: { items: 1 },
};

const items = [
  <img src="/assets/img/image-slider/health1.png" onDragStart={handleDragStart} className="img" />,
  <img src="/assets/img/image-slider/health2.jpg" onDragStart={handleDragStart} className="img"/>,
  <img src="/assets/img/image-slider/health3.jpg" onDragStart={handleDragStart} className="img"/>,
  <img src="/assets/img/image-slider/health4.jpg" onDragStart={handleDragStart} className="img"/>,
  <img src="/assets/img/image-slider/health5.jpg" onDragStart={handleDragStart} className="img"/>,
  <img src="/assets/img/image-slider/health6.jpg" onDragStart={handleDragStart} className="img"/>,
  <img src="/assets/img/image-slider/health7.jpg" onDragStart={handleDragStart} className="img"/>,
  <img src="/assets/img/image-slider/health8.jpg" onDragStart={handleDragStart} className="img"/>,
];
function Healthcareslider() {


  return (
    <>
      <br/><br/>
        <div class="container ">
          <div class="feature-brand-bg">
            <AliceCarousel mouseTracking items={items} responsive={responsive} infinite={Boolean} />
          </div> 
        </div> 
        
    </>
    
  );
};

export default Healthcareslider ;
