import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Button, Card } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCartArrowDown)

const TextCriticalCare = () => {
  return (
    <Fragment>
      <br /><br />
      <div className="div1">
        <div className="container">
          <div className="mb-5">
            <CardGroup >
              <Card className="div banner-content">
                <Link to="product/145">
                  <Card.Img variant="top" src="/assets/img/banner/nursing.jpg" />
                  <Card.Body>
                    <Card.Title className="card_title">Nursing For 4 Hours</Card.Title>
                    <Card.Text>Hire Nursing For 4 Hours</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>

              <Card className="div">
                <Link to="product/146">
                  <Card.Img variant="top" src="/assets/img/banner/nursing.jpg" />
                  <Card.Body>
                    <Card.Title className="card_title">Nursing For 8 Hours</Card.Title>
                    <Card.Text>Hire Nursing For 8 Hours</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>

              <Card className="div">
                <Link to="product/147">
                  <Card.Img variant="top" src="/assets/img/banner/nursing.jpg" />
                  <Card.Body>
                    <Card.Title className="card_title">Nursing For 12 Hours</Card.Title>
                    <Card.Text>Hire Nursing For 12 Hours</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>

              <Card className="div">
                <Link to="product/148">
                  <Card.Img variant="top" src="/assets/img/banner/nursing.jpg" />
                  <Card.Body>
                    <Card.Title className="card_title">Nursing For 24 Hours</Card.Title>
                    <Card.Text>Hire Nursing For 24 Hours</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>
            </CardGroup>
          </div>
          <div className="vl mb-3"><h3 className="vlh3"> Critical / Intensive Care</h3></div>
          <p>The ideal places for healing are at home. For patients who still need intensive care
            but are no longerin the acute stage of their disease, Glizmed ICU at Home services are
            available.</p>
          <p>The cost of Critical Care at Home services is substantially less than the cost of a hospital
            stay and includes the care and supervision of highly qualified critical care therapists, experts,
            and nurses.</p>
          <p className="mb-5">To ensure the best possible medical care, our clinical procedures were created in collaboration
            with renowned experts and top hospitals. Here are six ways we help patients and their families
            benefit from receiving critical care at home.</p>
        </div>
      </div>
      <div className="container">
        <div className="vl mb-3"><h3 className="vlh3">When Do You Need Critical Care?</h3></div>
        <p>In the event that no active interventions need to be made, many hospitals and doctors
          urge that long-term ICU care be given at home wherever possible.</p>
        <p>At home, the patient is more content, and healing can proceed unhindered by the risk of
          hospital-acquired illnesses. The family also has it easier financially because home health care is
          atleast 30% less expensive than hospital treatment.</p>
        <p className="mb-5">Many patients with respiratory or neurological diseases that take a long time to
          recover require this service, along with adequate equipment support and specialised care from
          qualified nurses.</p>

        <div className="vl mb-3"><h3 className="vlh3">Critical Care Packages</h3></div>
        <p className="mb-3">Care plans are created specifically for each patient's needs and are intended
          to provide high-quality, easily accessible health care at a reasonable cost.</p> 
          <h4 className="mb-3"><b>CRITICAL PACKAGE</b> </h4>
          <h5 className="mb-3"><b>Clinical team</b></h5>
          <ol>
            <li>2 ICU trained nurses – 12 hours each</li>
            <li>2 Nursing assistants – 12 hours each</li>
            <li>Physiotherapist (as advised by primary physician)</li>
            <li>Respiratory therapist (1-2 visits/week)</li>
            <li>ICU consumables</li>
            <li>Weekly clinical quality audits</li>
          </ol> 
        <h5 className="mb-3 mt-3"><b>Clinical team</b></h5>
        <ol>
          <li>Ventilator</li>
          <li>Infusion pumps</li>
          <li>DVT Pump</li>
          <li>Other equipment as per clinical assessment by Portea doctor</li>
          <li>Home visit reports</li>
          <li>E-Monitoring</li>
        </ol> 
        <h5 className="mb-3 mt-3"><b>On condition</b></h5>
        <p>GCS: 8 or less | Vitals: Unstable | Mobility: Restricted | Ventilation: Dependent</p>
        <div className="vl mb-3"><h3 className="vlh3">Why Choose Glizmed?</h3></div>
          <ol>
            <li>CERTIFIED ICU NURSES & ICU DOCTOR</li>
            <li>BEST IN CLASS ICU DEVICES</li>
            <li>COST ADVANTAGE</li>
            <li>WORKING WITH THE TREATING PHYSICIAN</li>
            <li>CONTINUOUS MONITORING</li>
            <li>LOWER RISK OF INFECTION</li>
          </ol> 
      </div>
    </Fragment>
  );
};

export default TextCriticalCare;
