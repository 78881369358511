

import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import Healthcare from "../health_care/HealthCare";


const HomeHealthCare = () => {

  return (
    <Fragment>
      <MetaTags>
        <title>Upto 70% OFF:Buy Health Care & Wellness Products</title>
        <meta name="description" content="Pharmaceutical Industry."/>
      </MetaTags>
      <LayoutOne headerContainerClass="container-fluid" headerPaddingClass="header-padding-2" headerTop="visible">
        <Healthcare />
      </LayoutOne>       
    </Fragment>
  );
};

export default HomeHealthCare;




