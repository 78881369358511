import React, { Fragment } from "react";
import { Table } from "react-bootstrap";

const TextElderCare = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="row ">
          <div className="col-lg-6">
            <div className="vl mb-3 mt-5"><h3 className="vlh3">Elder-Care Plans At Home</h3></div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Plan</th>
                  <th>Per Month*</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>REMOTE PACKAGE</td>
                  <td>2,999</td>    
                </tr>
                <tr>
                  <td>PRIME BASIC PACKAGE	</td>
                  <td>5,000</td>
                </tr>
                <tr>
                  <td>PRIME PREVENTIVE PACKAGE</td>
                  <td>10,000</td>
                </tr>
                <tr>
                  <td>PRIME PREMIUM PACKAGE	</td>
                  <td>20,000</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <h4 className="mb-3 mt-3"><b>REMOTE PACKAGE @ 2999/-</b></h4>
          <h5 className="mb-3 mt-3">Basic Eldercare plan at home at a cost Rs.100 per day, includes,</h5>
          <div className="container mb-3">
            <ul>
              <li>⮚ 15 doctor teleconsultation</li>
              <li>⮚ 6 online nutrition counseling</li>
              <li>⮚ 4 physiotherapy video-consultations</li>
              <li>⮚ 1 need assessment over the phone</li>
              <li>⮚ Health manager</li>
              <li>⮚ Emergency coordination</li>
            </ul>
          </div>

          <h4 className="mb-3 mt-3"><b>PRIME BASIC PACKAGE @ 5000/-</b></h4>
          <h5 className="mb-3 mt-3">Prime Basic Eldercare plan at home at a cost Rs.167 per day, includes,</h5>
          <div className="container mb-3">
            <ul>
              <li>⮚ 1 Dr.Visit / Comprehensive Assessment</li>
              <li>⮚ 2 Dr. Tele consultation</li>
              <li>⮚ 1 lab test which would cover 71 parameters</li>
              <li>⮚ Gift – Free Glucometer with 35 strips + Diet counseling</li>
              <li>⮚ Health manager</li>
              <li>⮚ Emergency coordination</li>
            </ul>
          </div>

          <h4 className="mb-3 mt-3"><b>PRIME PREVENTIVE PACKAGE @ 10,000/-</b></h4>
          <h5 className="mb-3 mt-3">Preventive Eldercare plan at home costs Rs.333 per day. includes,</h5>
          <div className="container mb-3">
            <ul>
              <li>⮚ 2 Dr.Visit / Comprehensive Assessment</li>
              <li>⮚ 4 Dr. Tele consultation</li>
              <li>⮚ 2 lab test which would cover 71 parameters</li>
              <li>⮚ 3 Nutrition counseling</li>
              <li>⮚ 3 Physio counseling</li>
              <li>⮚ Another 1 lab test- HBa1c + RBS</li>
              <li>⮚ Gift – Free Glucometer with 35 strips + Diet counseling</li>
              <li>⮚ Health manager</li>
              <li>⮚ Emergency coordination</li>
              <li>⮚ Social engagement activities</li>
            </ul>
          </div>

          <h4 className="mb-3 mt-3"><b>PRIME PREVENTIVE PACKAGE @ 20,000/-</b></h4>
          <h5 className="mb-3 mt-3">Preventive Eldercare plan at home costs Rs.667 per day. includes,</h5>
          <div className="container mb-3">
            <ul>
              <li>⮚ 4 Dr.Visit / Comprehensive Assessment</li>
              <li>⮚ 12 Dr. Tele consultation</li>
              <li>⮚ 2 lab test which would cover 71 parameters</li>
              <li>⮚ 5 Nutrition counseling</li>
              <li>⮚ 6 Physio counseling</li>
              <li>⮚ 3 emotional counseling sessions</li>
              <li>⮚ Another 1 lab test- HBa1c + RBS</li>
              <li>⮚ Gift – Free Glucometer with 35 strips + Diet counseling</li>
              <li>⮚ Health manager</li>
              <li>⮚ Emergency coordination</li>
              <li>⮚ Social engagement activities</li>
            </ul>
          </div>
          
        </div>
      </div>  
    </Fragment>
  );
};

export default TextElderCare;
