
import React, { Fragment, } from "react";


const HealthcareContent = () => {

  return (
    <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <h4 className="content_heading">Shop Safely And Worry-Free On Glizmed</h4>
              <p>Glizmed is a renowned name in the online shopping of Over The Counter or healthcare 
                products and other daily essentials. Ordering online on PharmEasy is convenient, 
                fast and hassle-free; you can avoid the annoyance of queuing up at your local pharmacy and
                also avail of huge discounts of up to 80% OFF. Purchasing the healthcare requirements such 
                as medical devices and nutritional supplements that you need will no longer burn a hole in 
                your pocket. At Glizmed, you are sure to find everything that you need because we have 
                products across all healthcare categories.
              </p>
              <h4 className="content_heading">Why choose Glizmed?</h4>
              <ol>
                <li>8400+ Brands</li>
                <li>35K products in our stock</li>
                <li>Delivery to 22000+ pin codes</li>
                <li>Delivered to 50 lakh+ families</li>
                <li>Over 2M customers served</li>
              </ol>
            </div>
            <div className="col-lg-1"></div>
          </div>
            
        </div>
        
    </Fragment>
  );
};

export default HealthcareContent;




