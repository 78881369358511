import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Button, Card } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCartArrowDown)
const TextGeriatric = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <CardGroup className="mb-5">
            <Card className="div banner-content">
                <Link to="product/161">
                  <Card.Img variant="top" src="/assets/img/banner/wheelchair.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Wheel Chair</Card.Title>
                    <Card.Text><b>Brand: </b>Arrex, Ezlife, Karma, Medemove</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/162">
                  <Card.Img variant="top" src="/assets/img/banner/icu5.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Hospital Cot – Icu 5 Function Motorised Cot – Excelsior
                    </Card.Title>
                    <Card.Text><b>Brand:</b>Arrex, Shambho life solution</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/163">
                  <Card.Img variant="top" src="/assets/img/banner/icu3.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Hospital Cot – Icu 3 Function Motorised Cot </Card.Title>
                    <Card.Text><b>Brand:</b>Arrex, Shambho life solution, Surgix</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div">
              <Link to="product/164">
                <Card.Img variant="top" src="/assets/img/banner/icu3 manual.jpg"/>
                <Card.Body>
                  <Card.Title className="card_title">Hospital Cot – Icu 3 Function Manual Cot</Card.Title>
                  <Card.Text><b>Brand:</b> Hitech, Shambho life solution, Arrex</Card.Text>
                  <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                </Card.Body>
              </Link>
            </Card>
          </CardGroup>

          <CardGroup className="mb-5">
            <Card className="div banner-content">
                <Link to="product/165">
                  <Card.Img variant="top" src="/assets/img/banner/semi manual.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Hospital Cot – Semi Fowler Manual</Card.Title>
                    <Card.Text><b>Brand: </b>ASI, Shambho life solution, Arrex, Hitech</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/166">
                  <Card.Img variant="top" src="/assets/img/banner/full.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Hospital Cot – Full Fowler Manual
                    </Card.Title>
                    <Card.Text><b>Brand:</b>Shambho life solution, Arrex, Hitech</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/167">
                  <Card.Img variant="top" src="/assets/img/banner/icu5 function.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Icu 5 Function Bed</Card.Title>
                    <Card.Text><b>Brand:</b>Arrex, Hightech, Surgix</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div">
              <Link to="product/168">
                <Card.Img variant="top" src="/assets/img/banner/full fowler.jpg"/>
                <Card.Body>
                  <Card.Title className="card_title">Full Fowler Cot</Card.Title>
                  <Card.Text><b>Brand:</b> Hitech, Shambho life solution, Arrex</Card.Text>
                  <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                </Card.Body>
              </Link>
            </Card>
          </CardGroup>
        </div>
      </div>
    </Fragment>
  );
};

export default TextGeriatric;
