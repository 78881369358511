import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Button, Card } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCartArrowDown)

const TextPhysiotherapy = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="vl mb-3"><h3 className="vlh3">What Is Physiotherapy?</h3></div>
            <p>Content is medically reviewed by:</p>
            <p>Physical therapy, commonly known as physiotherapy, is a branch of allied health that uses
            manual treatment, exercise therapy, electrotherapy, and biomechanics or kinesiology to help
            patients regain,maintain, and improve their physical mobility, strength, and function.</p>
            <p>Since they have received clinical training to assess, diagnose, and treat problems,
            physiotherapists are more equipped to assist patients in regaining mobility.
              Injuries and disabilities ranging from back pain, neck discomfort, knee pain,
            and ligament problems can all be treated by physiotherapists.,</p>

            <p> Parkinson's, paralysis, stroke, multiple sclerosis, and cerebral palsy patients
              might also benefit from the rehabilitation services provided by physiotherapy.
                Additionally, by seeing patients at home, physiotherapists are able to treat both acute and 
                chronic conditions.</p>
              <div className="vl mb-3 mt-5"><h3 className="vlh3">Benefits Of Physiotherapy At Home</h3></div> 
              <p className="mb-3">The usefulness of physiotherapy in aiding patients in regaining their health
              and improving their physical strength, function, and mobility has been demonstrated over time.
                Our physiotherapists personally visit you and carry out physiotherapy at home to further
                improve your experience with the treatment. Receiving physiotherapy services at home has
                  some advantages, such as:</p> 
              <div>
                <ol>
                  <li>Our postpartum caregivers are hand-selected and receive practical medical training from a hospital.</li>
                  <li>At home, we teach them to be perceptive to the needs of both the mother and the infant.</li>
                  <li>All of our baby-handling experts are mothers who have previous expertise.</li>
                  <li>We offer care packages for you and your infant on a 24/7, 365-day basis.</li>
                  <li>Leading hospitals suggest us as a partner for home care.</li>
                  <li>We're reasonably priced, trustworthy, and available.</li>
                </ol>
              </div>
              <p>Say good-bye to all your concerns and inconveniences associated with physiotherapy treatment 
              with the help of our reasonable and responsible home services.</p>
          </div>
          <div className="col-lg-5">
              <Card className="div banner-content">
                <Link to="product/152">
                  <Card.Img variant="top" src="/assets/img/banner/physiotherapy.jpg" height="240px"/>
                  <Card.Body>
                    <Card.Title className="card_title">Physiotherapy For 15 Days</Card.Title>
                    <Card.Text>Physiotherapy Service For 15 Days</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>  
            <Card className="div">
                <Link to="product/153">
                  <Card.Img variant="top" src="/assets/img/banner/physiotherapy.jpg" height="240px"/>
                  <Card.Body>
                    <Card.Title className="card_title">Physiotherapy For 30 Days</Card.Title>
                    <Card.Text>Physiotherapy Service For 30 Days</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>

          </div>
            <div className="vl mb-3 mt-5"><h3 className="vlh3">What Is Physiotherapy?</h3></div>
            <p>Call it the bane of the modern lifestyle but knee pain, back pain, and neck strain have become
              a part and parcel of life. When a certain type of pain does not subdue over a period of time and
              you experience it after doing a particular movement, then it is better to see a physiotherapist
              or get the service of a physiotherapist at home. You can also take benefit from our home
              physiotherapy service for a convenient treatment.</p>
            <p>People can receive physiotherapy for any of the following medical conditions: neurological
             problems, neuromusculoskeletal problems, cardiovascular problems, and respiratory problems.</p>
            <div className="row text_align">
              <div className="col-lg-3 box">
              <h4 className="color">Paralysis</h4>
              <p> Did you know that regular physiotherapy sessions can enhance muscle tone, allowing the 
                patient to move as freely as possible despite being paralysed?</p>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-3 box">
              <h4 className="color">Post-Surgical Care:</h4>
              <p>As a movement disorder, Parkinsonism. The patient may benefit from receiving physiotherapy
               in order to increase their mobility and independence.</p>
              
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-3 box">
              <h4 className="color">Urinary Catheterisation Care:</h4>
              <p>Our nurses are skilled in providing urinary catheterization care, and they can assist you 
                 with any step of the procedure at home, including catheter placement, catheter removal, 
                 and bladder washing.</p>
              </div>
            </div>  
            <div className="row text_align mt-5">
              <div className="col-lg-3 box">
              <h4 className="color">Wound Dressing:</h4>
              <p> Protect yourself from contagious diseases by getting vaccinated at home. For home care,
               we offer vaccinations for diseases like H1N1, typhoid, pneumonia, hepatitis, and more by 
               licenced nurses.</p>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-3 box">
              <h4 className="color">Oxygen Administration:</h4>
              <p>Post-surgical care is crucial and entails everything from managing pain and feeding to
                 controlling breathing and hydration intake. Our nurses will assist you with all of this in
                 the convenience of your home while helping you get better more quickly.</p>
              
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-3 box">
              <h4 className="color">Injection:</h4>
              <p className="mb-5">Our nurses are skilled in providing urinary catheterization care, and they can assist you 
                 with any step of the procedure at home, including catheter placement, catheter removal, 
                 and bladder washing.</p>
              </div>
            </div>  
        </div>
      </div>
    </Fragment>
  );
};

export default TextPhysiotherapy;
