import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";


const HomeThank = () => {

  return (
    
    <Fragment>
      <MetaTags>
        <title>Glizmed | Medical Equipment</title>
        <meta
          name="description"
          content="Pharmaceutical Industry."
        />
      </MetaTags>
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-2"
        headerTop="visible"
      >
      <div className="container thank">
        <h1 className="h1">Thanks!</h1>
        <br/>
        <h3>We Will Call  You In A While.Thank You For Connecting And Trusting Us.</h3>
      </div>
      
      
      </LayoutOne>
    </Fragment>
  );
};

export default HomeThank;
