
import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Button, Card } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCartArrowDown)
const TextCardiacEquipment = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <CardGroup className="mb-5">
            <Card className="div banner-content">
                <Link to="product/181">
                  <Card.Img variant="top" src="/assets/img/banner/Pulse_Oxymeter.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Pulse Oximeter</Card.Title>
                    <Card.Text><b>Brand: </b>Scure, Contec</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/182">
                  <Card.Img variant="top" src="/assets/img/banner/Ambulatory_BP_Monitor.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Ambulatory BP Monitor</Card.Title>
                    <Card.Text><b>Brand:</b>-</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/183">
                  <Card.Img variant="top" src="/assets/img/banner/ECG_Channel.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">ECG-Channel 3</Card.Title>
                    <Card.Text><b>Brand:</b>BPL</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div">
              <Link to="product/184">
                <Card.Img variant="top" src="/assets/img/banner/ECG_Channel_6.jpg"/>
                <Card.Body>
                  <Card.Title className="card_title">ECG-Channel 6</Card.Title>
                  <Card.Text><b>Brand:</b>BPL</Card.Text>
                  <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                </Card.Body>
              </Link>
            </Card>
          </CardGroup>

          <CardGroup className="mb-5">
            <Card className="div banner-content">
                <Link to="product/185">
                  <Card.Img variant="top" src="/assets/img/banner/Holter_Monitor_Trak_48.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Holter Monitor Trak 48</Card.Title>
                    <Card.Text><b>Brand: </b>BPL</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/186">
                  <Card.Img variant="top" src="/assets/img/banner/MULTIPARA_5_MONITOR.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Multipara Monitor MPM-12.1</Card.Title>
                    <Card.Text><b>Brand:</b>Niscomed</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/187">
                  <Card.Img variant="top" src="/assets/img/banner/MULTIPARA_2_MONITOR.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Multipara Monitor 2 Para</Card.Title>
                    <Card.Text><b>Brand:</b>Niscomed</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div">
              <Link to="product/188">
                <Card.Img variant="top" src="/assets/img/banner/INFUSION_PUMP.jpg"/>
                <Card.Body>
                  <Card.Title className="card_title">INFUSION PUMP CONTEC – SP750</Card.Title>
                  <Card.Text><b>Brand:</b> CONTEC</Card.Text>
                  <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                </Card.Body>
              </Link>
            </Card>
          </CardGroup>
        </div>
      </div>
    </Fragment>
  );
};

export default TextCardiacEquipment;
