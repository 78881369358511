
import React, { Fragment, } from "react";
import Healthcareslider from "../sliderpages/careslider";
import HealthcareContent from "./HealthcareContent";
import HealthcareIcontext from "./HealthcareIcontext";
import Healthcareproducts from "./Healthcareproducts";


const Healthcare = () => {

  return (
    <Fragment>
    <Healthcareslider />  
    <Healthcareproducts />
    <HealthcareContent />
    <HealthcareIcontext />
    </Fragment>
  );
};

export default Healthcare;




