import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";

const NavMenu = ({ strings, menuWhiteClass, sidebarMenu }) => {
  return (
    <div
      className={` ${
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      } `}
    >
      <nav>
        <ul>
          <li>
            <Link to={process.env.PUBLIC_URL + "/order-medicine"}>
              {"Order Medicines "}
              {strings[""]}
              {sidebarMenu }
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/health-care-products"}>
              {"Healthare Products"}
              {strings[""]}
              {sidebarMenu }
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/hospital-home"}>
              {"Care@Home"}
              {strings[""]}
              {sidebarMenu }
            </Link>
            <ul className="mega-menu">
              <li>
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/nursing"}>
                      {"Nursing"}
                      {strings[""]}
                      {sidebarMenu }
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/physiotherapy"}>
                      {"Physiotherapy"}
                      {strings[""]}
                      {sidebarMenu }
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/medical-equipment"}>
                      {"Medical Equipment"}
                      {strings[""]}
                      {sidebarMenu }
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/train-attendants"}>
                      {"Trained Attendants"}
                      {strings[""]}
                      {sidebarMenu }
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/elder-care"}>
                      {"Elder Care"}
                      {strings[""]}
                      {sidebarMenu }
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/mother-baby-care"}>
                      {"Mother & Baby Care"}
                      {strings[""]}
                      {sidebarMenu }
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/critical-care"}>
                      {"Critical Care"}
                      {strings[""]}
                      {sidebarMenu }
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/vaccintation"}>
                      {"Vaccination"}
                      {strings[""]}
                      {sidebarMenu }
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/nutrition-diet-consultation"}>
                      {"Nutrition & Diet Consultation"}
                      {strings[""]}
                      {sidebarMenu }
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "diabetic-care"}>
                      {"Diabetic Care"}
                      {strings[""]}
                      {sidebarMenu }
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
              </li>
            </ul>
          </li>

          
          <li>
          <Link to={process.env.PUBLIC_URL + "/lab-test"}>
              {"Lab Tests"}
              {strings[""]}
              {sidebarMenu }
            </Link>
          </li>
            
          <li>
            <Link to={process.env.PUBLIC_URL + "/surgeries"}>
              {"Surgeries"}
              {strings[""]}
              {sidebarMenu }
            </Link>
          </li>  
          <li>
            <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
              {"Consult Doctors"}
              {strings[""]}
              {sidebarMenu }
            </Link>
            <ul className="submenu">
              <li>
                <Link to={process.env.PUBLIC_URL + "/"}>
                  {"Find Doctors"}
                  {strings[""]}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/"}>
                  {" Video Consult"}
                  {strings[""]}
                </Link>
              </li> 
            </ul>
          </li>  
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object
};

export default multilanguage(NavMenu);
