import React, { Fragment } from "react";


const TextSurgeries2 = () => {

  return (
    <Fragment>
      <div className="sticky_div">
        <div className="sticky_form mt-1">
          <p className="sticky_p">Book Your Consultantion Today</p>
          <form action="/thank-you" className="mt-3 mb-3 form">
            <div className="form_body">
              <select id="city" name="city" className="city form-control mb-3" required>
                <option value="Asansol">Asansol</option>
                <option value="Bangalore">Bangalore</option>
                <option value="Bhubneswar" >Bhubneswar</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Chennai">Chennai</option>
                <option value="Delhi">Delhi</option>
                <option value="Gujrat">Gujrat</option>
                <option value="Mumbai">Mumbai</option>
                <option value="Nagpur">Nagpur</option>
                <option value="Kanpur">Kanpur</option>
              </select>
              <br />
              <input className="form-control" type="text" placeholder="Select Ailment*" required/>
              <br />
              <input className="form-control" type="text" placeholder="Name*" required/>
              <br />
              <input className="form-control" type="text" placeholder="Contact Number*" required/>
              <br />
              <button type="submit" className="package_button btn-block">Book Appointment</button> 
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default TextSurgeries2;