import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Button, Card } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCartArrowDown)
const TextOrthopedic = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <CardGroup>
              <Card className="div banner-content">
                  <Link to="product/178">
                    <Card.Img variant="top" src="/assets/img/banner/DVT_Pump_601E.jpg"/>
                    <Card.Body>
                      <Card.Title className="card_title">DVT-Pump – DVT Pump 601E</Card.Title>
                      <Card.Text><b>Brand: </b>Phlebo Press</Card.Text>
                      <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                    </Card.Body>
                  </Link>
              </Card>
              <Card className="div banner-content">
                  <Link to="product/179">
                    <Card.Img variant="top" src="/assets/img/banner/DVT_Pump_Venaflow.jpg"/>
                    <Card.Body>
                      <Card.Title className="card_title">DVT-Pump – Venaflow</Card.Title>
                      <Card.Text><b>Brand: </b>Aircast</Card.Text>
                      <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                    </Card.Body>
                  </Link>
              </Card>
              <Card className="div banner-content">
                  <Link to="product/180">
                    <Card.Img variant="top" src="/assets/img/banner/DVT_DEEP_DEVON.jpg"/>
                    <Card.Body>
                      <Card.Title className="card_title">Breast Pump (Manual/Electric)</Card.Title>
                      <Card.Text><b>Brand: </b>DEVON</Card.Text>
                      <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                    </Card.Body>
                  </Link>
              </Card>
            </CardGroup>
          </div>   
        </div>
        <br /> <br />
      </div>
    </Fragment>
  );
};

export default TextOrthopedic;
