import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Button, Card } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faArrowRight)
const Textequipment = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="vl mb-3"><h3 className="vlh3">MEDICAL EQUIPMENT FOR HOME CARE</h3></div>
            <p>It has never been so easy to rent or buy medical equipment and have it delivered right to your home.
            You or a loved one may need to rely on different medical equipment to get back to regular living during
              challenging times. In order to make healthcare more accessible and cheap for you, 
            glizmed offers a variety of medical equipment for rent or purchase.</p>
            <div className="vl mb-3 mt-5"><h3 className="vlh3">HOW CAN WE HELP</h3></div>
            <p>Purchase or rent any medical equipment you require from glizmed. Check out our extensive selection of 
            medical equipment, then rent or buy what you need online, by email, or over the phone.
            Your door will receive the delivery of your equipment.</p>
            <div className="vl mb-3 mt-5"><h3 className="vlh3">FAQ’S IN MEDICAL EQUIPMENT</h3></div>
            <h4>Q – Is there a minimum period for equipment rental?</h4>
            <p>A – You can rent equipment on a monthly basis. However, we do ensure flexibility on-need basis.</p>
            <h4>Q – Can I purchase equipment from Glizmed?</h4>
            <p>A – Yes, you can also purchase medical equipment from Glizmed.</p>
            <h4>Q – Do I need to collect the medical equipment from Glizmed?</h4>
            <p>A – Glizmed will home deliver the medical equipment to your doorstep.</p>
          </div>
          <div className="col-lg-5">
            <CardGroup>
              <Card className="div banner-content">
                  <Link to="geriatric-mobility-care">
                    <Card.Img variant="top" src="/assets/img/banner/chair.jpg"/>
                    <Card.Body>
                      <Card.Title>Geriatric And Mobility Care Equipment</Card.Title>
                      <Button className="package_button2"><FontAwesomeIcon  icon="fa-arrow-right" size="sm" /> View More</Button>
                    </Card.Body>
                  </Link>
              </Card>  
              <Card className="div">
                <Link to="sleep-therapy">
                  <Card.Img variant="top" src="/assets/img/banner/sleep_therapy.jpg"/>
                  <Card.Body>                    
                    <Card.Title>Sleep Therapy Equipment</Card.Title>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-arrow-right" size="sm" /> View More</Button>
                  </Card.Body>
                </Link>
              </Card>
            </CardGroup>
            <CardGroup>
              <Card className="div banner-content">
                  <Link to="mother-baby-equipment">
                    <Card.Img variant="top" src="/assets/img/banner/mother.jpg"/>
                    <Card.Body>
                      <Card.Title>Mother And Baby Care Equipment</Card.Title>
                      <Button className="package_button2"><FontAwesomeIcon  icon="fa-arrow-right" size="sm" /> View More</Button>
                    </Card.Body>
                  </Link>
              </Card>  
              <Card className="div">
                <Link to="orthopedic-and-physio-care">
                  <Card.Img variant="top" src="/assets/img/banner/physiotherapy.jpg"/>
                  <Card.Body>
                    <Card.Title>Orthopedic and Physiotherapy Care</Card.Title>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-arrow-right" size="sm" /> View More</Button>
                  </Card.Body>
                </Link>
              </Card>
            </CardGroup>
            <CardGroup>
              <Card className="div banner-content">
                  <Link to="cardiac-care">
                    <Card.Img variant="top" src="/assets/img/banner/cardiac_care.jpg"/>
                    <Card.Body>
                      <Card.Title>Cardiac Care</Card.Title>
                      <Button className="package_button2"><FontAwesomeIcon  icon="fa-arrow-right" size="sm" /> View More</Button>
                    </Card.Body>
                  </Link>
              </Card>  
              <Card className="div">
                <Link to="respiratory-care">
                  <Card.Img variant="top" src="/assets/img/banner/respiratory_care.jpg"/>
                  <Card.Body>
                    <Card.Title>Respiratory Care</Card.Title>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-arrow-right" size="sm" /> View More</Button>
                  </Card.Body>
                </Link>
              </Card>
            </CardGroup>
          </div>
        </div>
        <br />
          <br />
      </div>
    </Fragment>
  );
};

export default Textequipment;
