import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Button, Card } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCartArrowDown)
const TextMotherEquipment = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Card className="div banner-content">
                <Link to="product/177">
                  <Card.Img variant="top" src="/assets/img/banner/breast_pupm.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Breast Pump (Manual/Electric)</Card.Title><br/>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>
          </div>
                
        </div>
      </div>
    </Fragment>
  );
};

export default TextMotherEquipment;
