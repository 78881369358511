
import React, { Fragment, } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartArrowDown,faLock } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';




library.add(faCartArrowDown,faLock)
const HealthcareIcontext = () => {

  return (
    <Fragment>
        <div >
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3 box">
              <FontAwesomeIcon  icon="fa-cart-arrow-down" size="lg" className="font_color"/>
              <h4 className="color">1 Lakh+ Products</h4>
              <p>We maintain strict quality controls on all our partner retailers,
               so that you always get standard quality products.</p>
            </div>
            <div className="col-lg-3 box">
              <FontAwesomeIcon  icon="fa-lock" size="lg" className="font_color"/>
              <h4 className="color">Secure Payment</h4>
              <p>100% secure and trusted payment protection.</p>
            </div>
            <div className="col-lg-3 box">
              <FontAwesomeIcon  icon="fa-cart-arrow-down" size="lg" className="font_color"/>
              <h4 className="color">Easy Return</h4>
              <p>We have a new and dynamic return window policy for medicines and healthcare items.
               Refer FAQs section for more details..</p>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
        
    </Fragment>
  );
};

export default HealthcareIcontext;




