import React, { Fragment } from "react";

const TextNeutrition = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="row flex">
          <div className="vl mb-3"><h3>What Is Diet And Nutrition ?</h3></div>
          <p>Diet, on the other hand, is essentially the routine consumption of food and drink.
            Nutrition is the study or practise of consuming food in relation to a body's dietary demands.

            The act of restricting oneself to limited amounts or particular types of food, whether for a medical
            condition or to lose weight, is sometimes referred to as dieting.</p>

          <div className="vl mb-3"><h3>Key Offerings</h3></div> 

          <div className="row text_align">
            <div className="col-lg-3 box">
              <h4>Personalised Health Assessment:</h4>
              <p> People can get all the information they need from personal health assessments to effectively manage
                their health. This will assist people in stopping health issues before they start. Our medical
                professionals also examine your health history and present state of health when appropriately 
                diagnosing your health concern. Their work is not done yet. They assist you in setting goals and
                  developing an action plan to
                accomplish the health goals after they have recognised your health issue.</p>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-3 box">
            <h4>Personalised Nutrition Coaching:</h4>
            <p>For you, we view our customised nutrition coaching as a game-changer. We create a personalised
              nutrition plan for you that includes excellent health advice and recipes, 24/7 chat support,
              ongoing expert consultations, and routine progress reporting to help you get closer to your
                desired outcome. This is how we help you achieve good health. We are also aware of how bothersome
                calories may be. Because of this, we design individualised diet regimens that include only the 
            recommended number of calories per day for you—neither more nor fewer.</p>
            
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-3 box">
              <h4>Constant monitoring and Support:</h4>
              <p>Good health management frequently entails starting the programme off well by giving the customer
                sound counsel. At Portea, we support excellent health management. Excellent health management 
                strategies make sure that your health adviser keeps track of your development and puts 
              you back on the right path anytime you appear to be veering off course.</p>
            </div>
          </div> 
          <div className="vl mb-3 mt-5"><h3>Why Choose Glizmed Activ?</h3></div> 
            <h4 className="mb-3">Every one of the following nutrition categories is covered by our specialist 
                nutrition programmes:</h4>
              <div className="container">
                <ol>
                  <li>Family Nutrition</li>
                  <li>Sports Nutrition</li>
                  <li>Women Nutrition</li>
                  <li>Weight Management</li>
                  <li>Clinical Nutrition</li>
                  <li>Geriatric Nutrition</li>
                  <li>Pediatric Nutrition</li>
                  <li>Adolescent Nutrition</li>
                </ol> 
              </div>     
        </div>
        <br />
        <br />
      </div>
    </Fragment>
  );
};

export default TextNeutrition;
