import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";


const HomeLabTest = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Glizmed | Lab Test</title>
        <meta
          name="description"
          content="Pharmaceutical Industry."
        />
      </MetaTags>
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-2"
      >
      <div className="container">
        
      </div>
      </LayoutOne>
    </Fragment>
  );
};

export default HomeLabTest;
