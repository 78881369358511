import React, { Fragment ,useState } from "react";
import MetaTags from "react-meta-tags";
import TextVaccination from "../../data/context/TextVaccination";
import LayoutOne from "../../layouts/LayoutOne";


const HomeNursing = () => {

  return (
    
    <Fragment>
      <MetaTags>
        <title>Glizmed | Vaccination</title>
        <meta
          name="description"
          content="Pharmaceutical Industry."
        />
      </MetaTags>
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-2"
        headerTop="visible"
      >
      <div className="background_image" style={{  
        backgroundImage: "url(" + "/assets/img/banner/vaccination.jpg" + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',  
        backgroundRepeat: 'no-repeat'
        }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 homecare_text" >
              <h1 className="h1">Vaccination at Home </h1>
            </div>
            <div className="col-lg-4 ">
              <form action="/thank-you" className="mt-3 mb-3 form">
                <div className="form_header">
                  <h3 className="h3">Home Visit</h3>
                </div>
                <div className="form_body">
                  <label>Name*</label>
                  <input className="input-sm mb-2 mt-2" type="text" placeholder="Please Provide Your Name" required/>
                  <label>Mobile Number</label>
                  <input className="input-sm mb-2 mt-2" type="text" placeholder="To Coordinate With You" required/>
                  <label htmlFor="city">City*</label>
                    <select id="city" name="city" className="city" required>
                      <option value="None"></option>
                      <option value="Asansol">Asansol</option>
                      <option value="Bangalore">Bangalore</option>
                      <option value="Bhubneswar" >Bhubneswar</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Chennai">Chennai</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Gujrat">Gujrat</option>
                      <option value="Mumbai">Mumbai</option>
                      <option value="Nagpur">Nagpur</option>
                      <option value="Kanpur">Kanpur</option>
                    </select>  
                  <p className="condition">*I authorize Gliztech representative to contact me.
                    I understand that this will override the DND status on my mobile number.</p> 
                  <button type="submit" className="package_button">Book Now</button> 
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <br/><br />
      <TextVaccination />
      </LayoutOne>
    </Fragment>
  );
};

export default HomeNursing;
