

import React, { Fragment ,useState } from "react";
import MetaTags from "react-meta-tags";
import Textequipment from "../../data/context/Textequipment";
import LayoutOne from "../../layouts/LayoutOne";


const HomeEquipment = () => {

  return (
    
    <Fragment>
      <MetaTags>
        <title>Glizmed |Medical Equipment</title>
        <meta
          name="description"
          content="Pharmaceutical Industry."
        />
      </MetaTags>
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-2"
        headerTop="visible"
      >
      <div className="background_image" style={{  
        backgroundImage: "url(" + "/assets/img/banner/medicalequipment.jpg" + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',  
        backgroundRepeat: 'no-repeat'
        }}>
        <div className="container">
          <div className="row">
          <div className="col-lg-8 homecare_text" >
            <h1 className="h1">Medical Equipment</h1>
          </div>
          <div className="col-lg-4 ">
              <form action="/thank-you" className="mt-3 mb-3 form">
                <div className="form_header">
                  <h3 className="h3">Book Equipment</h3>
                </div>
                <div className="form_body">
                  <label>Name*</label>
                  <input className="input-sm mb-2 mt-2" type="text" placeholder="Please Provide Your Name" required/>
                  <label>Mobile Number</label>
                  <input className="input-sm mb-2 mt-2" type="text" placeholder="To Coordinate With You" required/>
                  <label htmlFor="city">City*</label>
                    <select id="city" name="city" className="city" required>
                      <option value="None"></option>
                      <option value="Asansol">Asansol</option>
                      <option value="Bangalore">Bangalore</option>
                      <option value="Bhubneswar" >Bhubneswar</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Chennai">Chennai</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Gujrat">Gujrat</option>
                      <option value="Mumbai">Mumbai</option>
                      <option value="Nagpur">Nagpur</option>
                      <option value="Kanpur">Kanpur</option>
                    </select>  
                    <br /><br />
                <div data-toggle="buttons">
                  <label>
                    <input type="radio" name="options" id="option2" required /> Rental
                  </label>
                  <label >
                    <input type="radio" name="options" id="option3" required /> Purchase
                  </label>
                </div><br />               
              <p className="condition">*I authorize Glizmed representative to contact me.
              I understand that this will override the DND status on my mobile number.</p> 
              <button type="submit" className="package_button">Book Now</button> 
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <br/><br />
      <Textequipment />

      </LayoutOne>
 
    </Fragment>
  );
};

export default HomeEquipment;

