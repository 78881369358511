import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";

const MobileNavMenu = ({ strings }) => {
  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
       <ul>
        <li>
          <Link to={process.env.PUBLIC_URL + "/order-medicine"}>
            {"Order Medicines "}
            {strings[""]}
          </Link>
        </li>

        <li>
            <Link to={process.env.PUBLIC_URL + "/health-care-products"}>
              {"Healthare Products"}
              {strings[""]}

            </Link>
        </li>

        <li className="menu-item-has-children">
            <Link to={process.env.PUBLIC_URL + "/hospital-home"}>
              {"Care@Home"}
              {strings[""]}

            </Link>
            <ul className="sub-menu">
              <li>
                <Link to={process.env.PUBLIC_URL + "/nursing"}>
                  {"Nursing"}
                  {strings[""]}
    
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/physiotherapy"}>
                  {"Physiotherapy"}
                  {strings[""]}
    
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/medical-equipment"}>
                  {"Medical Equipment"}
                  {strings[""]}
    
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/train-attendants"}>
                  {"Trained Attendants"}
                  {strings[""]}
                  
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/elder-care"}>
                  {"Elder Care"}
                  {strings[""]}
                  
                </Link>
              </li>

              <li>
                <Link to={process.env.PUBLIC_URL + "/mother-baby-care"}>
                  {"Mother & Baby Care"}
                  {strings[""]}
                  
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/critical-care"}>
                  {"Critical Care"}
                  {strings[""]}
                  
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/vaccintation"}>
                  {"Vaccination"}
                  {strings[""]}
                  
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/nutrition-diet-consultation"}>
                  {"Nutrition & Diet Consultation"}
                  {strings[""]}
                  
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "diabetic-care"}>
                  {"Diabetic Care"}
                  {strings[""]}
    
                </Link>
              </li>
            </ul>
        </li>

        <li>
        <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
            {"Lab Tests"}
            {strings[""]}
            
          </Link>
        </li>

        <li>
          <Link to={process.env.PUBLIC_URL + "/surgeries"}>
            {"Surgeries"}
            {strings[""]}
            
          </Link>
        </li> 

        <li className="menu-item-has-children">
            <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
              {"Consult Doctors"}
              {strings[""]}
              
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to={process.env.PUBLIC_URL + "/"}>
                  {"Find Doctors"}
                  {strings[""]}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/"}>
                  {" Video Consult"}
                  {strings[""]}
                </Link>
              </li> 
            </ul>
          </li>  
      </ul>
    </nav>
  );
};

MobileNavMenu.propTypes = {
  strings: PropTypes.object
};

export default multilanguage(MobileNavMenu);
