import React, { Fragment } from "react";

const TextMother = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="row flex">
          <div className="vl mb-3"><span></span><h3>What Is New Born Baby & Mother Care Program?</h3></div>
          <p>Prenatal, during pregnancy, and postpartum care are the three components of pregnancy care.
           While prenatal care receives a lot of attention, postnatal care is just as significant. In actuality,
            the postpartum period is challenging, and the new mother needs a lot of care,
             direction, and attention to ensure a proper recovery and both the mother and 
             the child's excellent health. A new mother may have emotional ups and downs after giving birth.
              The first 24 hours following birth are crucial for both the mother and the child, according to
               the World Health Organization (WHO), and during this time,
           the new mother and the child should receive the appropriate care.</p>
          <p>A thoughtfully created Kanga and Roo programme is provided by Glizmed. It is a postnatal care programme that relieves the new parents' effort and stress. Being a parent is a challenging experience, and in today's society, when some couples are compelled to live apart from their families or don't have family support during this crucial time, they are left to care for the infant on their own. This results in a lot of effort and worry for the new parents, 
          particularly during the crucial first few months for both the mother and the infant.</p>
          <p className="mb-5">In accordance with the Kanga and Roo programme, Glizmed designates a 
          caregiver with medical training to assist the mother in taking care of the newborn child
           and to provide postpartum care for the mother.</p>


          <div className="vl mb-3"><span></span><h3>When Do You Need Us?</h3></div> 
          <p className="mb-5">You may count on us to provide you with thorough neonatal care through our newborn
            baby care service, as well as postpartum care from a highly dependable, considerate,
            and skilled caregiver. Our caregivers are exceptionally skilled at delivering postpartum care
            and newborn baby care because they are moms themselves.
            Among the services offered by our Kanga and Roo programme are:</p> 

          <div className="row text_align">
            <div className="col-lg-3 box">
            <h4>New Mother Care</h4>
            <p> Many new mothers experience postpartum depression following the stimulating labour and delivery
              process. Massages and emotional support could aid in the mother's recovery and enjoyment of parenthood.
              For the new mother to fully recuperate, post-operative and post-delivery care is crucial. Therefore,
              this programme is suitedyou if you're looking for helpful professional care for the mother.</p>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-3 box">
            <h4>New Born Baby Care</h4>
            <p>A newborn baby is extremely fragile and requires particular care. A new mother frequently experiences 
            difficulty during this time. Our caregivers are specially educated to help the new mother care for the
              baby, and they can also assist with lactation, massages, and postnatal exercises. Therefore, simply make
            a reservation with us for 
            a new-born baby care service and take it easy while you enjoy motherhood.</p>
            
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-3 box">
            <h4>The Coming Home Service</h4>
            <p>You can take advantage of our coming home services after leaving a nursing home or hospital.
              These services include a medical visit within the first month, doctor phone consultations,
              and guidance on lactation, nutrition, and postpartum recovery. Our caregivers are also well 
            qualified to provide the mother with postpartum care and newborn care.</p>
            </div>
          </div>
          <div className="vl mb-3 mt-5"><span></span><h3>How Can We Help?</h3></div>
          <h4 className="mb-3">IMPORTANCE OF NEW BORN BABY & MOTHER CARE</h4>
          <p>Prenatal, during pregnancy, and postpartum care are the three components of pregnancy care.
          While prenatal care receives a lot of attention, postnatal care is just as significant. In actuality,
            the postpartum period is challenging, and the new mother needs a lot of care,
            direction, and attention to ensure a proper recovery and both the mother and 
            the child's excellent health. A new mother may have emotional ups and downs after giving birth.
              The first 24 hours following birth are crucial for both the mother and the child, according to
              the World Health Organization (WHO), and during this time,
          the new mother and the child should receive the appropriate care.</p>
          <div>
            <h4>Kanga and Roo program will help you cope with:</h4>
            <ol>
              <li>confusion over the best way to care for your child</li>
              <li>eating difficulties at first and after recovery</li>
              <li>the total transformation of your life as you knew it prior to delivery</li>
              <li>taking a short break while your infant is cared for by a trusted third party</li>
            </ol>  
          </div>   

          <div className="vl mb-3 mt-5"><span></span><h3>Things To Expect From New Born Baby 
            Care And Mother Care</h3>
          </div>
          <div>
            <ol>
              <li>New born baby care</li>
              <li>Breastfeeding care</li>
              <li>Personalized Care</li>
              <li>Post delivery mother care</li>
              <li>Counseling and Emotional support for the mother</li>
              <li>Affordable and easily accessible service</li>
            </ol>
          </div>
          <div className="vl mb-3 mt-5"><span></span><h3>How To Prepare For A New Born Baby Care And Mother
            Care</h3>
          </div>
          <h4>However, there are several considerations that must be made because this type of treatment does
            not require the same level of essential care as treating any condition;</h4>
          <div>
            <ol>
              <li>Make a list of the issues you want your caregiver to handle.</li>
              <li>Make a list of dos and don'ts for the caregiver so that she is aware of what is and is not acceptable in your eyes.</li>
              <li>Be ready to learn about the specifics of baby health care, and keep an open mind.</li>
              <li>Don't freak out or worry yourself sick over taking care of the infant; the caregiver is well-trained to do it.</li>
              <li>Even while it's always a good idea to remain vigilant, strive to be a little more trusting. Our carers, who were moms themselves, will provide you with dependable and trustworthy help.</li>
            </ol>
          </div>
          <p>– Visit&nbsp;4 Hours&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; – Full Day 10 Hours
            <br />
            – Night 12 Hours
            <br />
            – Stay @Home 24 Hours
          </p>

          <div className="vl mb-3 mt-5"><span></span><h3>Cost Of Postnatal Services</h3></div>
          <p>Postnatal care is reasonably priced and provides services that are worth the money.
            There are numerous insurance companies that provide coverage for maternity costs.
            The maternity benefit provides insurance coverage for hospitalisation linked to pregnancy
            for up to 30 days prior to delivery and for up to 60 days following delivery.
            If an infant is found to have a serious sickness or a congenital disorder, 
            the coverage is also extended to them. The benefit cap for maternity and new-born claims 
            is set in accordance with natural births and Caesarean deliveries.</p>
          <p>It is now quite simple for new parents to obtain reputable new-parent and mother care services,
            where they may feel at ease about the quality of the baby's healthcare and the mother's postpartum
            care, thanks to technological advancements and the rise of home health care services. As a result,
            keep in mind that
            we are only a click away if you ever need postpartum or new mother care.
          </p>  
          <div className="vl mb-3 mt-5"><span></span><h3>Why Choose Our Post-Natal Care Service ?</h3></div>
          <div>
            <ol>
              <li>Our postpartum caregivers are hand-selected and receive practical medical training from a hospital.</li>
              <li>At home, we teach them to be perceptive to the needs of both the mother and the infant.</li>
              <li>All of our baby-handling experts are mothers who have previous expertise.</li>
              <li>We offer care packages for you and your infant on a 24/7, 365-day basis.</li>
              <li>Leading hospitals suggest us as a partner for home care.</li>
              <li>We're reasonably priced, trustworthy, and available.</li>
            </ol>
          </div>

          <div className="vl mb-3 mt-5"><span></span><h3>Patient Testimonials</h3></div>
          <div className="row">
            <div className="col-lg-5">
              <div class="testimonial">
                <div className="media">
                  <p className="media_text">S</p>
                </div>
                <br />
                <h3 className="text_center">Shraddha</h3>
                <br />
                <p>Special thanks to Ms. Gayathri, who is currently caring for my child.When resolving 
                crises and attending to my baby's needs, the staff is courteous and composed.</p>
              </div>
              <br/>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-5">
              <div class="testimonial">
                <div className="media">
                  <p className="media_text">N</p>
                </div>
                <br />
                <h3 className="text_center">Nidhi</h3>
                <br />
                <p>Special thanks to Ms. Gayathri, who is currently caring for my child.When resolving 
                crises and attending to my baby's needs, the staff is courteous and composed.</p>
              </div>
              <br/>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TextMother;
