import React, { Fragment ,useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

const Popup6 = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Fragment>
      <Modal show={show}
        onHide={handleClose}
         className="modal_background"> 
        <div className="d-flex justify-content-center">
          <div className="sticky_form mt-1">
            <p className="sticky_p"><b>Book an appointment for Lasik with our expert surgeon</b></p>
            <form action="/thank-you" className="mt-3 mb-3 form">
              <div className="form_body">
                <select id="city" name="city" className="city form-control mb-3" required>
                  <option value="Asansol">Asansol</option>
                  <option value="Bangalore">Bangalore</option>
                  <option value="Bhubneswar" >Bhubneswar</option>
                  <option value="Chandigarh">Chandigarh</option>
                  <option value="Chennai">Chennai</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Gujrat">Gujrat</option>
                  <option value="Mumbai">Mumbai</option>
                  <option value="Nagpur">Nagpur</option>
                  <option value="Kanpur">Kanpur</option>
                </select>
                <br />
                <input className="form-control" type="text" placeholder="Name*" required/>
                <br />
                <input className="form-control" type="text" placeholder="Contact Number*" required/>
                <br />
                <button type="submit" className="package_button btn-block ">Book Appointment</button> 
                <p>By submitting the form, you agree to Glizmed T & C</p>
              </div>
            </form>
          </div>
        </div>
        
      </Modal>
  
      <div className="SurgicalSolutions_module_wrapper">
        <Link onClick={handleShow}>
          <div className="flex_col">
            <div className="inneritem">
              <span className="outer_span">
                <img alt="Ailment Icon" src="/assets/img/icon-img/Lasik.webp" 
                  decoding="async" data-nimg="intrinsic" className="span1_img"
                  />  
              </span> 
            </div> 
            <p className="mt-3 ">Lasik</p>  
          </div>
        </Link>
      </div>
    </Fragment>
    );
};

export default Popup6;