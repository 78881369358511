
import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Button, Card } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCartArrowDown)
const TextRespiratory = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <CardGroup className="mb-5">
            <Card className="div banner-content">
                <Link to="product/189">
                  <Card.Img variant="top" src="/assets/img/banner/BIPAP_A40.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">BIPAP A40 – Ventilator</Card.Title>
                    <Card.Text><b>Brand: </b>Philips</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/190">
                  <Card.Img variant="top" src="/assets/img/banner/Trilogy_100.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Trilogy 100 – Ventilator</Card.Title>
                    <Card.Text><b>Brand:</b>Philips</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/191">
                  <Card.Img variant="top" src="/assets/img/banner/Suction_Apparatus.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Suction Apparatus (Double JAR) – EZ Life</Card.Title>
                    <Card.Text><b>Brand:</b>EZ Life</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div">
              <Link to="product/192">
                <Card.Img variant="top" src="/assets/img/banner/Suction_Apparatus.jpg"/>
                <Card.Body>
                  <Card.Title className="card_title">Suction Apparatus (double JAR)</Card.Title>
                  <Card.Text><b>Brand:</b>Niscomed</Card.Text>
                  <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                </Card.Body>
              </Link>
            </Card>
          </CardGroup>

          <CardGroup className="mb-5">
            <Card className="div banner-content">
                <Link to="product/193">
                  <Card.Img variant="top" src="/assets/img/banner/SUCTION_APPARATU_SINGLE.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Suction Apparatus (Single Jar)</Card.Title>
                    <Card.Text><b>Brand: </b>Niscomed</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/194">
                  <Card.Img variant="top" src="/assets/img/banner/SUCTION_APPARATU_SINGLE_EZ_Life.jpg"/>
                  <Card.Body>
                    <Card.Title className="card_title">Suction Apparatus (Single Jar) – EZ Life</Card.Title>
                    <Card.Text><b>Brand:</b>EZ Life</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div banner-content">
                <Link to="product/195">
                  <Card.Img variant="top" src="/assets/img/banner/Stellar_100_Ventilator.png"/>
                  <Card.Body>
                    <Card.Title className="card_title">Stellar-100 Ventilator</Card.Title>
                    <Card.Text><b>Brand:</b>Stellar</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
            </Card>  
            <Card className="div">
              <Link to="product/196">
                <Card.Img variant="top" src="/assets/img/banner/Philips_Bipap_Pro.jpg"/>
                <Card.Body>
                  <Card.Title className="card_title">Philips Bipap Pro</Card.Title>
                  <Card.Text><b>Brand:</b> Philips</Card.Text>
                  <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                </Card.Body>
              </Link>
            </Card>
          </CardGroup>
        </div>
      </div>
    </Fragment>
  );
};

export default TextRespiratory;
