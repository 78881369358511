import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { Button, Card } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCartArrowDown)

const TextNursing = () => {
  return (
    <Fragment>
    <br/><br/>
    <div className="div1">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="vl mb-3"><h3 className="vlh3"> What Does Home Nursing Care Means?</h3></div>
            <p>A comprehensive range of healthcare services that can be conveniently delivered at your home 
            are included in the Home Nursing Service. While being just as effective as the medical care provided
            in a hospital or nursing home,nurses for home care services are typically less expensive than 
            hospitals and nursing homes.</p>
            <p>An in-home nursing service provides the same individualised nursing care at home as is often 
            provided in a hospital, but is more sympathetic to the patient, becomes a part of the patient's family,
            and forms an emotional connection with the patient and their family.</p>
            <p>Among others, registered nurses, physiotherapists, and occupational therapists deliver the services
              given by home care nursing.As a result, you do not need to worry about the home nurse's level of
              service.</p>
            <p className="mb-5">The terms "in-home care," "home care," and "home health care" have all been used interchangeably
            in the past.However, in recent years, people have come to recognise that home health care, often 
            known as in-home nursing, is essentially skilled nursing care, as opposed to in-home care, which
              refers to non-medical care services like personal care, companionship, and monitoring.</p>

            <h4>SHORT TERM NURSING CARE</h4>
            <p>For a set amount of time, such as one to four hours, short term nurses offer nursing services.
            Following surgery, an injury, or another medical condition that is expected to get better,
            short-term nursing care is a temporary form of medical aftercare.</p> 
            <p className="mb-5"><b>Specialties:</b> At-home vaccinations, injections, ECGs, and dressing or
            wound care.</p>
            
            <h4>LONG TERM NURSING CARE</h4>
            <p>For 12 to 24 hours, long-term nurses provide nursing care. To satisfy the physical, social, and 
              emotional requirements of those who are chronically ill or disabled,long-term nursing care 
              encompasses a wide range of medical, surgical, and physical care services.</p> 
              <p className="mb-5"><b>Specialties:</b> Home Tracheostomy Care, Home Ventilator Care, Home Catheter Care, Home 
              Infusion Care, Home Ryle's Tube Care, and Home IV Line Care</p> 

            <h4>NURSING ATTENDANTS AT HOME</h4>
            <p>Patients who require nursing care have their everyday needs taken care of by nursing staff.
            They offer consoling care. They cannot, however, perform any medical treatments.</p>
            <p className="mb-5"><b>Specialties:</b> Caretakers, support care, elderly patient care, caretaker,
              and helping with everyday routines are among their specialties.</p>
          </div>
          <div className="col-lg-5">
            <CardGroup >
              <Card className="div banner-content">
                <Link to="product/145">
                  <Card.Img variant="top" src="/assets/img/banner/nursing.jpg" />
                  <Card.Body>
                    <Card.Title className="card_title">Nursing For 4 Hours</Card.Title>
                    <Card.Text>Hire Nursing For 4 Hours</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>

              <Card className="div">
                <Link to="product/146">
                  <Card.Img variant="top" src="/assets/img/banner/nursing.jpg" />
                  <Card.Body>
                    <Card.Title className="card_title">Nursing For 8 Hours</Card.Title>
                    <Card.Text>Hire Nursing For 8 Hours</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>
            </CardGroup>
            <br />
            <br />
            <CardGroup >
              <Card className="div">
                <Link to="product/147">
                  <Card.Img variant="top" src="/assets/img/banner/nursing.jpg" />
                  <Card.Body>
                    <Card.Title className="card_title">Nursing For 12 Hours</Card.Title>
                    <Card.Text>Hire Nursing For 12 Hours</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>

              <Card className="div">
                <Link to="product/148">
                  <Card.Img variant="top" src="/assets/img/banner/nursing.jpg" />
                  <Card.Body>
                    <Card.Title className="card_title">Nursing For 24 Hours</Card.Title>
                    <Card.Text>Hire Nursing For 24 Hours</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>
            </CardGroup>
            <br />
            <br />
            <CardGroup >
              <Card className="div">
                <Link to="product/149">
                  <Card.Img variant="top" src="/assets/img/banner/nursing.jpg" />
                  <Card.Body>
                    <Card.Title className="card_title">15 Days Package</Card.Title>
                    <Card.Text>Hire Nursing For 4 Hours</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>

              <Card className="div">
                <Link to="product/150">
                  <Card.Img variant="top" src="/assets/img/banner/nursing.jpg" />
                  <Card.Body>
                    <Card.Title className="card_title">20 Days Package</Card.Title>
                    <Card.Text>Hire Nursing For 12 Hours</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>
              <Card className="div">
                <Link to="product/151">
                  <Card.Img variant="top" src="/assets/img/banner/nursing.jpg" />
                  <Card.Body>
                    <Card.Title className="card_title"> 30 Days Package</Card.Title>
                    <Card.Text>Hire Nursing For 24 Hours</Card.Text>
                    <Button className="package_button2"><FontAwesomeIcon  icon="fa-cart-arrow-down" size="sm" /> Add To Cart</Button>
                  </Card.Body>
                </Link>
              </Card>
            </CardGroup>
          </div>
        </div>
      </div>
    </div>
      <div className="container">
        <div className="vl mb-3"><h3 className="vlh3">When Do You Need Us?</h3></div> 
        <p className="mb-5">Treatment of a disease or injury is the primary objective of home nursing services.
          The home nursing services often include monitoring major illnesses and unstable health status,
          treating surgical or pressure sore wounds, educating patients and caregivers,
            administering intravenous or nutrition therapy,
          administering injections, and providing rehabilitation therapies.</p> 

        <div className="row">
          <div className="col-lg-3 box">
            <h4 className="color">Vaccination:</h4>
            <p> Protect yourself from contagious diseases by getting vaccinated at home. For home care,
            we offer vaccinations for diseases like H1N1, typhoid, pneumonia, hepatitis, and more by 
            licenced nurses.</p>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-3 box">
            <h4 className="color">Post-Surgical Care:</h4>
            <p>Post-surgical care is crucial and entails everything from managing pain and feeding to
              controlling breathing and hydration intake. Our nurses will assist you with all of this in
              the convenience of your home while helping you get better more quickly.</p>
          
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-3 box">
            <h4 className="color">Urinary Catheterisation Care:</h4>
            <p>Our nurses are skilled in providing urinary catheterization care, and they can assist you 
              with any step of the procedure at home, including catheter placement, catheter removal, 
              and bladder washing.</p>
          </div>
        </div>  
        <div className="row mt-5 mb-5">
          <div className="col-lg-3 box">
            <h4 className="color">Wound Dressing:</h4>
            <p> Protect yourself from contagious diseases by getting vaccinated at home. For home care,
            we offer vaccinations for diseases like H1N1, typhoid, pneumonia, hepatitis, and more by 
            licenced nurses.</p>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-3 box">
            <h4 className="color">Oxygen Administration:</h4>
            <p>Post-surgical care is crucial and entails everything from managing pain and feeding to
              controlling breathing and hydration intake. Our nurses will assist you with all of this in
              the convenience of your home while helping you get better more quickly.</p>
          
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-3 box">
            <h4 className="color">Injection:</h4>
            <p className="mb-5">Our nurses are skilled in providing urinary catheterization care, and they can assist you 
              with any step of the procedure at home, including catheter placement, catheter removal, 
              and bladder washing.</p>
          </div>
        </div>  
      </div>
    </Fragment>
  );
};

export default TextNursing;
