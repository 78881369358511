import React, { Fragment } from "react";
import TextSurgeries1 from "./TextSurgeries1";
import TextSurgeries2 from "./TextSurgeries2";


const TextSurgeries = () => {

  return (
    <Fragment>
      <div className="container">
        <div className="banner">
          <img src="/assets/img/banner/surgeriesbanner.png" className="w-100 rounded_border" />
        </div>
      </div>
      <div className="container">
        <div className="pb-40 mb-8">
          <h4 className="heading_surgeries mt-5">We are experts in Surgical solutions for 50+
            ailments.</h4>
        </div>
      <div className="row">
        <div className="col-lg-4">
          <TextSurgeries2 />
        </div>
        <div className="col-lg-1"></div>
        <div className="col-lg-7">
          <TextSurgeries1 />
        </div>
      </div>
        
      </div>
    </Fragment>
  );
};

export default TextSurgeries;
