
import React, { Fragment, } from "react";


const Healthcareproducts = () => {

  return (
    <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3 box">
              <div className="row"> 
                <div className="col-lg-3 ">
                  <img src="/assets/img/product/medical/Covid Essentials.webp" height="70px" width="70px"/>
                </div>
                <div className="col-lg-6 textbox"><p>Covid Essentials</p></div>
                <div className="col-lg-3 textbox2"><p>Upto 77% OFF</p></div>
              </div>
            </div>
            <div className="col-lg-3 box">
              <div className="row"> 
                <div className="col-lg-3 ">
                  <img src="/assets/img/product/medical/Personal Care.webp" height="70px" width="70px"/>
                </div>
                <div className="col-lg-6 textbox"><p>Personal Care</p></div>
                <div className="col-lg-3 textbox2"><p>Upto 80% OFF</p></div>
              </div>
            </div>
            <div className="col-lg-3 box">
              <div className="row"> 
                <div className="col-lg-3 ">
                  <img src="/assets/img/product/medical/Health Food And Drinks.webp" height="70px" width="70px"/>
                </div>
                <div className="col-lg-6 textbox"><p>Health Food And Drinks</p></div>
                <div className="col-lg-3 textbox2"><p>Upto 57% OFF</p></div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>

          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3 box">
              <div className="row"> 
                <div className="col-lg-3 ">
                  <img src="/assets/img/product/medical/Beauty.webp" height="70px" width="70px"/>
                </div>
                <div className="col-lg-6 textbox"><p>Beauty</p></div>
                <div className="col-lg-3 textbox2"><p>Upto 35% OFF</p></div>
              </div>
            </div>
            <div className="col-lg-3 box">
              <div className="row"> 
                <div className="col-lg-3 ">
                  <img src="/assets/img/product/medical/Skin Care.webp" height="70px" width="70px"/>
                </div>
                <div className="col-lg-6 textbox"><p>Skin Care</p></div>
                <div className="col-lg-3 textbox2"><p>Upto 50% OFF</p></div>
              </div>
            </div>
            <div className="col-lg-3 box">
              <div className="row"> 
                <div className="col-lg-3 ">
                  <img src="/assets/img/product/medical/Home Care.webp" height="70px" width="70px"/>
                </div>
                <div className="col-lg-6 textbox"><p>Home Care</p></div>
                <div className="col-lg-3 textbox2"><p>Upto 35% OFF</p></div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>

          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3 box">
              <div className="row"> 
                <div className="col-lg-3 ">
                  <img src="/assets/img/product/medical/Ayurvedic Care.webp" height="70px" width="70px"/>
                </div>
                <div className="col-lg-6 textbox"><p>Ayurvedic Care</p></div>
                <div className="col-lg-3 textbox2"><p>Upto 70% OFF</p></div>
              </div>
            </div>
            <div className="col-lg-3 box">
              <div className="row"> 
                <div className="col-lg-3 ">
                  <img src="/assets/img/product/medical/Sexual Wellness.webp" height="70px" width="70px"/>
                </div>
                <div className="col-lg-6 textbox"><p>Sexual Wellness</p></div>
                <div className="col-lg-3 textbox2"><p>Upto 53% OFF</p></div>
              </div>
            </div>
            <div className="col-lg-3 box">
              <div className="row"> 
                <div className="col-lg-3 ">
                  <img src="/assets/img/product/medical/Fitness Supplements.webp" height="70px" width="70px"/>
                </div>
                <div className="col-lg-6 textbox"><p>Fitness Supplements</p></div>
                <div className="col-lg-3 textbox2"><p>Upto 80% OFF</p></div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>

          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3 box">
              <div className="row"> 
                <div className="col-lg-3 ">
                  <img src="/assets/img/product/medical/Mother And Baby Care.webp" height="70px" width="70px"/>
                </div>
                <div className="col-lg-6 textbox"><p>Mother And Baby Care</p></div>
                <div className="col-lg-3 textbox2"><p>Upto 50% OFF</p></div>
              </div>
            </div>
            <div className="col-lg-3 box">
              <div className="row"> 
                <div className="col-lg-3 ">
                  <img src="/assets/img/product/medical/Healthcare Devices.webp" height="70px" width="70px"/>
                </div>
                <div className="col-lg-6 textbox"><p>Healthcare Devices</p></div>
                <div className="col-lg-3 textbox2"><p>Upto 65% OFF</p></div>
              </div>
            </div>
            <div className="col-lg-3 box">
              <div className="row"> 
                <div className="col-lg-3 ">
                  <img src="/assets/img/product/medical/Healthcare Condition.webp" height="70px" width="70px"/>
                </div>
                <div className="col-lg-6 textbox"><p>Healthcare Condition</p></div>
                <div className="col-lg-3 textbox2"><p>Upto 65% OFF</p></div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>

          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3 box">
              <div className="row"> 
                <div className="col-lg-3 ">
                  <img src="/assets/img/product/medical/Diabetic Care.webp" height="70px" width="70px"/>
                </div>
                <div className="col-lg-6 textbox"><p>Diabetic Care</p></div>
                <div className="col-lg-3 textbox2"><p>Upto 65% OFF</p></div>
              </div>
            </div>
            <div className="col-lg-3 box">
              <div className="row"> 
                <div className="col-lg-3 ">
                  <img src="/assets/img/product/medical/Elderly Care.webp" height="70px" width="70px"/>
                </div>
                <div className="col-lg-6 textbox"><p>Elderly Care</p></div>
                <div className="col-lg-3 textbox2"><p>Upto 45% OFF</p></div>
              </div>
            </div>
            <div className="col-lg-3 box">
              <div className="row"> 
                <div className="col-lg-3 ">
                  <img src="/assets/img/product/medical/Accessories And Wearables.webp" height="70px" width="70px"/>
                </div>
                <div className="col-lg-6 textbox"><p>Accessories And Wearables</p></div>
                <div className="col-lg-3 textbox2"><p>Upto 35% OFF</p></div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
        <br /><br />
    </Fragment>
  );
};

export default Healthcareproducts;




