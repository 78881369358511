import React, { Fragment ,useState} from "react";
import { Card } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Popup1 from "./SurgeriesPopup1/Popup1";
import Popup10 from "./SurgeriesPopup1/Popup10";
import Popup11 from "./SurgeriesPopup1/Popup11";
import Popup12 from "./SurgeriesPopup1/Popup12";
import Popup13 from "./SurgeriesPopup1/Popup13";
import Popup14 from "./SurgeriesPopup1/Popup14";
import Popup15 from "./SurgeriesPopup1/Popup15";
import Popup16 from "./SurgeriesPopup1/Popup16";
import Popup17 from "./SurgeriesPopup1/Popup17";
import Popup18 from "./SurgeriesPopup1/Popup18";
import Popup19 from "./SurgeriesPopup1/Popup19";
import Popup2 from "./SurgeriesPopup1/Popup2";
import Popup20 from "./SurgeriesPopup1/Popup20";
import Popup3 from "./SurgeriesPopup1/Popup3";
import Popup4 from "./SurgeriesPopup1/Popup4";
import Popup5 from "./SurgeriesPopup1/Popup5";
import Popup6 from "./SurgeriesPopup1/Popup6";
import Popup7 from "./SurgeriesPopup1/Popup7";
import Popup8 from "./SurgeriesPopup1/Popup8";
import Popup9 from "./SurgeriesPopup1/Popup9";


const TextSurgeries1 = () => {


  return (
    <Fragment>

      <div id="surgeries" className="surgeries_div_background"> 
        <div className="container_module">
          <div className="rounded_div_1 mb-5">
            <div className="container">
              <h5 className="mt-3 mb-4 ml-3 ">Popular Surgeries</h5>
              <div className="flex_div_1">
                <Popup1 />
                <Popup2 />    
                <Popup3 />   
                <Popup4 />   
                <Popup5 />
                <Popup6 />          
                <Popup7 />    
                <Popup8 />
                <Popup9 />
                <Popup10 />       
                <Popup11 />    
                <Popup12 />    
                <Popup13 />   
                <Popup14 />    
                <Popup15 />    
                <Popup16 />    
                <Popup17 />    
                <Popup18 />   
                <Popup19 />    
                <Popup20 />    
              </div>
            </div>   
          </div>
          <div className="rounded_div_1 mb-5">
            <CardGroup className="mb-4">
              <Card className="div">
                <Link>
                  <Card.Img variant="top" src="/assets/img/banner/general_surgeries.jpg" />
                    <Card.Body>
                      <Card.Title className="card_title">General Surgeries</Card.Title>
                      <Card.Text>
                      8 ailments.
                      </Card.Text>
                      
                    </Card.Body>
                </Link>
              </Card>
              <Card className="div">
                <Link>
                  <Card.Img variant="top" src="/assets/img/banner/Proctology.jpg" />
                    <Card.Body>
                      <Card.Title className="card_title">Proctology</Card.Title>
                      <Card.Text>
                        5 ailments.
                      </Card.Text>
                      
                    </Card.Body>
                </Link>
              </Card>
              <Card className="div">
                <Link>
                  <Card.Img variant="top" src="/assets/img/banner/Ophthamology.jpg" />
                    <Card.Body>
                      <Card.Title className="card_title">Opthamology</Card.Title>
                      <Card.Text>
                        4 ailments.
                      </Card.Text>  
                    </Card.Body>
                </Link>
              </Card>
            </CardGroup>  
            <CardGroup className="mb-4">
              <Card className="div">
                <Link>
                  <Card.Img variant="top" src="/assets/img/banner/Gynaecology.jpg" />
                    <Card.Body>
                      <Card.Title className="card_title">Gynaecology</Card.Title>
                      <Card.Text>
                      9 ailments.
                      </Card.Text>
                      
                    </Card.Body>
                </Link>
              </Card>
              <Card className="div">
                <Link>
                  <Card.Img variant="top" src="/assets/img/banner/Urology.jpg" />
                    <Card.Body>
                      <Card.Title className="card_title">Urology</Card.Title>
                      <Card.Text>
                        5 ailments.
                      </Card.Text>
                      
                    </Card.Body>
                </Link>
              </Card>
              <Card className="div">
                <Link>
                  <Card.Img variant="top" src="/assets/img/banner/Cosmetic.jpg" />
                    <Card.Body>
                      <Card.Title className="card_title">Cosmetic</Card.Title>
                      <Card.Text>
                        6 ailments.
                      </Card.Text>  
                    </Card.Body>
                </Link>
              </Card>
            </CardGroup>  
            <CardGroup className="mb-4">
              <Card className="div">
                <Link>
                  <Card.Img variant="top" src="/assets/img/banner/Orthopedics.jpg" />
                    <Card.Body>
                      <Card.Title className="card_title">Orthopedics</Card.Title>
                      <Card.Text>
                      7 ailments.
                      </Card.Text>
                      
                    </Card.Body>
                </Link>
              </Card>
              <Card className="div">
                <Link>
                  <Card.Img variant="top" src="/assets/img/banner/Hair_Treatment.jpg" />
                    <Card.Body>
                      <Card.Title className="card_title">Hair Treatment</Card.Title>
                      <Card.Text>
                        2 ailments.
                      </Card.Text>
                      
                    </Card.Body>
                </Link>
              </Card>
              <Card className="div_hidden">
                <Link>
                  <Card.Img variant="top" src="/assets/img/banner/doctor.jpg" />
                    <Card.Body>
                      <Card.Title className="card_title">Hair Treatment</Card.Title>
                      <Card.Text>
                        2 ailments.
                      </Card.Text>
                      
                    </Card.Body>
                </Link>
              </Card>
              
            </CardGroup>  
             
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TextSurgeries1;